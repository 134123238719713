.HomeContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}

.arrow-info {
  background: none;
  color: var(--switch-light);
}

/*Title*/
.home-tittle {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home-tittle h1 {
  font-size: 10rem;
  color: var(--color-primary);
}

.home-tittle h1 span {
  color: var(--color-blue);
}

.home-content {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}

.home-content p {
  width: 80%;
  text-align: justify;
  text-justify: inter-word;
  letter-spacing: -0.05em;
  font-weight: 500;
  color: var(--color-description);
}

.home-content .button-info {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: 0.8rem;
  text-decoration: none;
  color: var(--switch-light);
  background-color: var(--color-blue);
  border: 2px solid var(--color-blue);
  border-radius: 2rem;
  cursor: pointer;
}

.home-content .button-info:hover {
  background-color: var(--color-blueHover);
  color: var(--switch-light);
  transition: 400ms all;
}

/*Images*/

.content-images-home {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.image {
  width: 49%;
  height: 28.125rem;
  display: flex;
  margin: 0.5%;
}

.image img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}

@media (max-width: 1384px) {
  .home-content .button-info {
    width: 15%;
  }
}

@media (max-width: 1024px) {
  .home-tittle h1 {
    font-size: 8rem;
    width: 100%;
  }

  .home-content .button-info {
    width: 20%;
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 1360px) and (max-width: 1368px) and (min-height: 768px) and (max-height: 768px) {
  .HomeContainer {
    margin-top: 1rem;
  }

  .home-tittle h1 {
    font-size: 7rem;
  }

  .home-content .button-info {
    width: 14%;
  }

  .image {
    width: 100%;
    height: 25rem;
  }
}

@media (max-width: 768px) {
  .home-title {
    justify-content: center;
  }
  .home-tittle h1 {
    font-size: 6rem;
    text-align: center;
  }

  .content-images-home {
    flex-direction: column;
  }

  .image {
    width: 100%;
    height: 22rem;
  }

  .image-two {
    display: none;
  }
}

@media (max-width: 560px) {
  .home-tittle h1 {
    font-size: 5rem;
  }

  .home-content {
    width: 100%;
    flex-direction: column;
  }

  .home-content p {
    width: 90%;
  }
  .home-content .button-info {
    font-size: 15px;
    width: 50%;
  }

  @media (max-width: 470px) {
    .home-tittle h1 {
      font-size: 3.5rem;
    }
  }

  @media (max-width: 425px) {
    .HomeContainer {
      margin-top: 0;
    }
  }

  @media (max-width: 375px) and (max-height: 812px) {
    .image {
      width: 100%;
      height: 20rem;
    }
  }

  @media (max-width: 360px) {
    .image {
      height: 19rem;
    }
  }
}
