.container-footer {
  position: absolute;
  display: flex;
  height: 20vh;
  width: 100%;
  color: var(--switch-light);
  flex-direction: column;
  background-color: var(--color-blue);
  justify-content: center;
  align-items: center;
}

.footer-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: var(--switch-light);
  background-color: var(--color-blue);
}

.footer-h {
  align-items: center;
  display: flex;
  width: 90%;
  font-size: 2rem;
  color: var(--switch-light);
  background-color: var(--color-blue);
}

.footer-about {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: -1.875rem;
  color: var(--switch-light);
  background-color: var(--color-blue);
}

.box-about {
  align-items: center;
  justify-content: space-around;
  display: flex;
  width: 90%;
  height: 10vh;
  align-items: center;
  color: var(--switch-light);
  background-color: var(--color-blue);
}

.about-links {
  display: flex;
  gap: 1.25rem;
  font-size: 1.2rem;
  color: var(--switch-light);
  background-color: var(--color-blue);

  width: 60%;
}

.about-links a {
  text-decoration: none;
  color: var(--switch-light);
  background-color: var(--color-blue);
}

.about-rights {
  display: flex;
  font-size: 1.2rem;
  width: 40%;
  justify-content: end;
  color: var(--switch-light);
  background-color: var(--color-blue);
}

.p-rights {
  color: var(--switch-light);
  background-color: var(--color-blue);
}

.box-soicons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  color: var(--switch-light);
  background-color: var(--color-blue);
}

@media screen and (min-width: 1368px) and (max-width: 1368px) and (min-height: 768px) and (max-height: 768px) {
  .container-footer {
    height: 15vh;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .about-rights {
    justify-content: end;
  }

  .about-links,
  .about-rights,
  .icons {
    font-size: 0.9rem;
  }
}

@media (min-width: 620px) and (max-width: 768px) {
  .about-links,
  .icons {
    font-size: 0.8rem;
  }

  .box-soicons {
    width: 50%;
  }

  .about-rights {
    width: 50%;
    font-size: 0.8rem;
  }
}

@media (max-width: 620px) {
  .container-footer {
    flex-direction: column;
    height: 15vh;
  }
  .box-about {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 10vh;
  }

  .about-links,
  .about-social {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .about-links,
  .icons {
    font-size: 1rem;
  }

  .box-soicons {
    justify-content: space-between;
    width: 100%;
    margin-top: 0.9375rem;
    flex-direction: column;
  }

  .about-rights {
    margin-top: 10px;
    font-size: 0.7rem;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .footer-h {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 430px) {
  .container-footer {
    margin-top: 3rem;
  }

  .box-about {
    height: 8vh;
  }
}

@media screen and (min-width: 320px) and (max-width: 430px) and (min-height: 780px) and (max-height: 850px) {
  .p-rights {
    margin-bottom: 10px;
  }

  .box-about {
    width: 100%;
  }
}