.HomeNosotros {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

/*Title*/

.nosotros-title {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  flex-wrap: wrap;
}

.title-one {
  color: var(--color-primary);
}

.title-two {
  color: var(--color-blue);
}

h2 {
  font-size: 4rem;
  font-weight: 600;
}

/*Contenido*/

.nosotros-container {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 8px 0px;
}

.nosotros-contenid {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

.title-container {
  font-size: 2.5rem;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: var(--color-primary);
  font-weight: 450;
}

.card span {
  font-size: 1.25rem;
  font-weight: 300;
}

.nosotros-contenid span {
  font-size: 1.625rem;
  font-weight: 300;
  color: var(--color-primary);
}

/*Cards*/

.nosotros-cards {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.card {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  border: 1px var(--color-primary);
  background-color: var(--card-background);
  height: 7rem;
  padding: 1.5rem;
  color: var(--switch-light);
  border-radius: 10px;
}

hr {
  width: 90%;
  margin-top: 10rem;
}

.card h2 {
  font-size: 1.2rem;
  background-color: var(--card-background);
}

.card span {
  font-size: 1rem;
  background-color: var(--card-background);
}

.TbTargetArrow {
  background-color: var(--card-background);
}

.MdOutlineMiscellaneousServices {
  background-color: var(--card-background);
  margin-right: 0.188rem;
}
/*Responsive*/

@media (max-width: 1300px) {
  .card span {
    font-size: 1rem;
  }

  .nosotros-contenid span {
    font-size: 1.25rem;
  }
}

@media (max-width: 920px) {
  .card {
    height: 8rem;
  }

  .nosotros-title {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media screen and (min-width: 1360px) and (max-width: 1368px) and (min-height: 768px) and (max-height: 768px) {
  .HomeNosotros {
    gap: 1rem;
  }

  .nosotros-title h2 {
    font-size: 3.5rem;
  }

  .nosotros-contenid span {
    font-size: 1.25rem;
  }

  .nosotros-cards {
    margin-bottom: -3rem;
  }
}

@media (max-width: 810px) {
  .nosotros-contenid span {
    font-size: 1.063rem;
  }
  .card span {
    font-size: 0.75rem;
  }
}

@media (max-width: 540px) {
  .card span {
    font-size: 0.625rem;
  }

  hr {
    display: none;
  }

  .title-container {
    font-size: 2.188rem;
  }
  .card {
    height: 6.5rem;
  }

  h2 {
    font-size: 3rem;
  }
}

@media screen and (min-width: 420px) and (max-width: 425px) and (min-height: 710px) and (max-height: 715px) {
  .HomeNosotros {
    gap: 0.5rem;
    margin-top: 4rem;
  }

  .nosotros-cards .card {
    margin-bottom: -2rem;
  }

  .card:last-child {
    height: 6.5rem;
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 2.5rem;
  }

  .nosotros-cards {
    gap: 3rem;
  }

  .nosotros-cards {
    gap: 1.5rem;
  }

  .nosotros-contenid span {
    font-size: 0.813rem;
  }
  .card {
    height: 10rem;
  }
  .card h2 {
    font-size: 1.2rem;
  }

  .card span {
    font-size: 0.688rem;
  }
}

@media (max-width: 375px) {
  h2 {
    font-size: 2rem;
  }
  
  .nosotros-title {
    margin-top: 3rem;
  }
  .card {
    height: 6.5rem;
  }
  
  .nosotros-cards {
    gap: 1rem;
  }
  
  .card span {
    font-size: 0.563rem;
  }

  .contact-container h4 {
    margin-top: -2rem;
  }
}


@media (max-width: 320px) {
  .card span {
    font-size: 9px;
  }
}