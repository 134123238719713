@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-primary: #ffffff;
  --color-background: #161616;
  --color-blue: #2853ce;
  --color-description: #777777;
  --switch-light: #d8dbe0;
  --switch-dark: #28292c;
  --card-background: #2e2e2e;
}

[data-theme="dark"] {
  --color-primary: #000000;
  --color-blue: #2853ce;
  --color-blueHover: #1e43a7;
  --color-background: #ececec;
  --color-description: #777777;
  --switch-light: #d8dbe0;
  --switch-dark: #28292c;
  --card-background: #151515;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  transition: all 0.5s ease-in-out;
}

body {
  background: var(--color-background);
  background-size: cover;
  background-repeat: no-repeat;
}
