.contact-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
}

.buttonComuni {
  cursor: pointer;
}

.info-top {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.info-top h4 {
  font-size: 6rem;
  color: var(--color-primary);
}

.info-top h4 span {
  color: var(--color-blue);
}

.info-top p {
  color: var(--color-description);
  width: 65%;
  text-align: center;
}

.container-inputs-contact {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 2rem;
}

.container-inputs-contact span {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 1rem 2.5rem 1rem 2.5rem;
  font-size: 0.9rem;

  color: var(--color-description);
  border: 1.5px solid var(--color-description);
  border-radius: 3rem;
}

.container-inputs-contact span input {
  border: none;
  outline: none;
  color: var(--color-primary);
  background: none;
}

.container-inputs-contact span input[type="number"]::-webkit-inner-spin-button,
.container-inputs-contact span input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrow-contact {
  background: none;
}

.container-inputs-contact span input[type="number"] {
  -moz-appearance: textfield;
}

.container-inputs-contact button {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  gap: 0.3rem;
  padding: 1.2rem;
  border: 1.5px solid var(--color-blue);
  color: var(--switch-light);
  background-color: var(--color-blue);
  border-radius: 3rem;
}

.container-inputs-contact button:hover {
  color: var(--switch-light);
  background-color: var(--color-blue);

  transition: 400ms all;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.671);
}

/**********RESPONSIVE**********/

@media (max-width: 1450px) {
  .contact-container .info-top h4 {
    width: 70%;
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .contact-container .info-top h4 {
    font-size: 3.5rem;
  }

  .contact-container .info-top p {
    font-size: 0.9rem;
  }

  .container-inputs-contact span {
    width: 45%;
    padding: 0.8rem 2rem 0.8rem 2rem;
    font-size: 0.8rem;
  }

  .container-inputs-contact button {
    width: 45%;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 1360px) and (max-width: 1368px) and (min-height: 768px) and (max-height: 768px) {
  .info-top h4 {
    font-size: 3.5rem;
  }

  .contact-container {
    gap: 1.5rem;
  }

  .container-inputs-contact {
    gap: 1rem;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 820px) {
  .contact-container {
    margin-bottom: 2rem;
  }
}

@media (max-width: 750px) {
  .contact-container .info-top h4 {
    width: 80%;
    font-size: 2.5rem;
  }

  .contact-container .info-top p {
    width: 80%;
    font-size: 0.8rem;
  }

  .container-inputs-contact span {
    width: 50%;
    padding: 0.7rem 2rem 0.7rem 2rem;
    font-size: 0.8rem;
  }

  .container-inputs-contact button {
    width: 50%;
    font-size: 0.8rem;
  }
}

@media (max-width: 570px) {
  .container-inputs-contact span {
    width: 65%;
  }

  .container-inputs-contact button {
    width: 65%;
  }
}

@media screen and (min-width: 400px) and (max-width: 425px) and (min-height: 700px) and (max-height: 720px) {
  .contact-container {
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .container-inputs-contact {
    gap: 1rem;
  }
}

@media (max-width: 425px) {
  .contact-container .info-top h4 {
    margin-top: 8rem;
    font-size: 2rem;
  }

  .contact-container .info-top p {
    width: 80%;
    font-size: 0.65rem;
  }

  .container-inputs-contact span {
    width: 80%;
    padding: 0.7rem 2rem 0.7rem 2rem;
    font-size: 0.7rem;
  }

  .container-inputs-contact button {
    width: 80%;
    font-size: 0.8rem;
  }

  @media (max-width: 375px) and (max-height: 812px) {
    .container-inputs-contact {
      gap: 1.1rem;
    }
  }
}
