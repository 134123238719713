.navbar {
  width: 100%;
  height: 8vh;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbarClosed {
  width: 100%;
  height: 8vh;
  position: sticky;

  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20rem;
}

.arrow-left {
  display: none;
}

.arrow-right {
  display: none;
}

.logo-blue {
  width: 6%;
}

.containerLogo {
  display: none;
}

.container-nav .anchor-theme {
  display: none;
}

.container-nav {
  width: 15%;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.anchorF {
  text-decoration: none;
  color: white;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.anchorF:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.anchorF:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.anchor {
  text-decoration: none;
  color: var(--color-primary);
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.anchor:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--color-blue);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.anchor:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.anchor:hover {
  color: var(--color-blue);
}

.button-contact {
  text-align: center;
  text-decoration: none;
  color: var(--switch-light);
  border: 2px solid var(--color-blue);
  background: var(--color-blue);
  border-radius: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
}

.button-contact:hover {
  background-color: var(--color-blue);
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.671);
  color: var(--switch-light);
  transition: 400ms all;
}

.buttonBlue {
  display: none;
}

.buttonNav {
  display: none;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/*******SWITCH DE CAMBIO DE TEMA*******/
.toggle-switch {
  position: relative;
  width: 65px;
  height: 30px;
}

.toggle-switch .switch-label {
  position: absolute;
  width: 100%;
  height: 30px;
  background-color: var(--switch-dark);
  border-radius: 25px;
  cursor: pointer;
  border: 3px solid var(--switch-dark);
}

.switch-label .checkbox {
  position: absolute;
  display: none;
}

.switch-label .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox:checked ~ .slider {
  background-color: var(--switch-light);
}

.slider::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  width: 22px;
  height: 20px;
  border-radius: 50%;
  -webkit-box-shadow: inset 12px -4px 0px 0px var(--switch-light);
  box-shadow: inset 12px -4px 0px 0px var(--switch-light);
  background-color: var(--switch-dark);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox:checked ~ .slider::before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
  background-color: var(--switch-dark);
  -webkit-box-shadow: none;
  box-shadow: none;
}

/***********************************/

@media (max-width: 1024px) {
  .logo-blue {
    width: 9%;
  }

  .navbarClosed {
    gap: 10rem;
  }
}

@media (max-width: 768px) {
  .logo-blue {
    width: 13%;
  }

  .navbarClosed {
    gap: 5rem;
  }

  .container-nav {
    gap: 0.5rem;
    font-size: 0.9rem;
  }

  .button-contact {
    font-size: 0.75rem;
  }

  .buttons-container {
    gap: 0.2rem;
  }

  /*******SWITCH DE CAMBIO DE TEMA*******/
  .toggle-switch {
    width: 51px;
    height: 26px;
  }

  .toggle-switch .switch-label {
    position: absolute;
    width: 100%;
    height: 26px;
  }

  .switch-label .slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .slider::before {
    top: 1px;
    left: 1px;
    width: 20px;
    height: 18px;
  }

  .checkbox:checked ~ .slider::before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
}

@media (max-width: 430px) {
  .buttonNav {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 100;
    color: var(--switch-light);
    font-size: 1.3rem;
    visibility: visible;
    animation: fadeIn 3s ease-in;
  }
  .buttonBlue {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 100;
    color: var(--color-blue);
    font-size: 1.5rem;
    display: flex;
  }

  .containerLogo {
    width: 90%;
    display: flex;
    justify-content: flex-start;
  }

  .buttonNav {
    display: flex;
  }

  .logo-blue {
    display: none;
  }

  .logo-white {
    width: 6rem;
    display: flex;
    position: absolute;
    top: 1rem;
    background-color: var(--color-blue);
  }

  .navbarClosed {
    width: 60%;
    height: 100vh;
    background-color: var(--color-blue);
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    right: 0;
    top: 0;
    gap: 0;
    position: fixed;
    transform: translateX(100%) !important;
    transition: transform 0.5s ease-in-out;
    overflow-x: hidden;
  }

  .navbar {
    width: 60%;
    height: 100vh;
    background-color: var(--color-blue);
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    right: 0;
    top: 0;
    gap: 0;
    transition: 0.5s ease all;
    transform: translateX(0);
  }

  .container-nav {
    width: 100%;
    height: 100%;
    background-color: var(--color-blue);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 4rem;
  }

  .button-contact {
    display: none;
  }

  .anchor {
    width: 90%;
    display: flex;
    align-items: center;
    background-color: var(--color-blue);
    color: var(--switch-light);
  }

  .anchor label {
    background-color: var(--color-blue);
    border-bottom: 1px solid var(--color-blue);
  }

  .arrow-right {
    display: flex;
    position: absolute;
    background-color: var(--color-blue);
    right: 1rem;
  }

  /*******SWITCH DE CAMBIO DE TEMA*******/
  .toggle-switch {
    display: none;
  }

  .container-nav .anchor {
    display: flex;
  }

  .container-nav .anchor-theme .form {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    gap: 5.8rem;
    margin-left: -0.5rem;
    justify-content: space-around;
  }

  .container-nav .anchor .form input {
    position: absolute;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 10px;
  }

  /*******SWITCH DE CAMBIO DE TEMA*******/
  .toggle-switch {
    display: none;
  }

  .container-nav .anchor-theme {
    display: flex;
  }

  .container-nav .anchor-theme .form {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    gap: 5.8rem;
    margin-left: -0.5rem;
    justify-content: space-around;
  }

  .container-nav .anchor .form input {
    position: absolute;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 10px;
  }
}


